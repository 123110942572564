import React, { useEffect, useState } from 'react'
import { get, isEmpty, find } from 'lodash'
import * as sdk from '../../sdk'
import ItemSelector from '../../components/order/ItemSelector'
import Progress from '../../components/Progress'
import { Header } from '../../components/Header'
import {
  useAppContext,
  useAppContextActions
} from '../../context/App/AppContext'
import { TProduct } from '../../types'
import { getProductFromOrderById, getLineItems } from '../../utils/order'
import { getProductPrice } from '../../utils/product'
import { sendSentryError } from '../../utils/sentry'
import { PRODUCT_IMAGE_URL } from '../../constants'
import {
  Container,
  Body,
  ItemSelectorContainer,
  ProgressContainer
} from './styled-components'
import { DonationButton } from './DonationButton'
import OrderDetailsWithPay from '../../components/order/OrderDetailsWithPay'
import { getPurchaseLimit } from '../../utils/purchaseLimit'
import { useParams } from 'react-router-dom'
import { Donations } from './Donations'

export function SizzleContainer() {
  const {
    order,
    productsById,
    project,
    booking,
    totalPrice,
    donation,
    stripe
  } = useAppContext()
  const { bookingId } = useParams<{
    storeCode: string
    bookingId: string
  }>()
  const { setOrder } = useAppContextActions()
  const [bookingsProducts, setBookingsProducts] = useState<TProduct[]>([])
  const [donationOpen, setDonationOpen] = useState(false)

  const reachLimit = Number(totalPrice) > getPurchaseLimit(booking)
  const handlePageShow = (e: any) => {
    if (e.persisted) {
      window.location.reload()
    }
  }
  const currency = project?.currency?.toLowerCase()

  useEffect(() => {
    window.addEventListener('pageshow', handlePageShow)
    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  })

  useEffect(() => {
    const bookingProductIds = new Set(get(booking, 'productIds') ?? [])

    let filteredProducts = (Object.values(productsById) ?? []).filter(
      (product) => bookingProductIds.has(product.id)
    )

    const hasSos = find(filteredProducts, { name: 'Sausage' })

    /*
     * If the booking has a Sausage, we want to move it to the top of the list
     */
    if (hasSos) {
      filteredProducts = filteredProducts.filter(
        (product) => product.id !== hasSos.id
      )

      filteredProducts.unshift(hasSos)
    }

    setBookingsProducts(filteredProducts)
  }, [booking, productsById])

  const handleAdd = (product: TProduct) => {
    const newOrder = isEmpty(order) ? [] : [...order!]
    const orderedProduct = find(newOrder, {
      productId: product.id
    })

    if (!isEmpty(orderedProduct)) {
      orderedProduct!.quantity += 1
    } else {
      const price = find(product.prices, {
        product: product.id,
        currency
      })

      if (!isEmpty(price)) {
        newOrder.push({
          quantity: 1,
          productId: product.id,
          productName: product.name,
          priceId: price!.id,
          unit_amount: price!['unit_amount']
        })
      }
    }
    setOrder(newOrder)
  }

  const handleRemove = (product: TProduct) => {
    const newOrder = isEmpty(order) ? [] : [...order!]
    const orderedProduct = find(newOrder, { productId: product.id })
    if (!isEmpty(orderedProduct) && orderedProduct!.quantity > 0) {
      orderedProduct!.quantity -= 1
    }
    setOrder!(newOrder)
  }

  const handlePay = async () => {
    if (!stripe) {
      sendSentryError('Stripe is not loaded')
      return
    }

    try {
      const res = await sdk.checkout({
        projectId: get(project, 'projectId')!,
        bookingId,
        lineItems: getLineItems(order!),
        donation
      })

      const sessionId = get(res, 'data.checkout.sessionId', '')
      const { error } = await stripe.redirectToCheckout({
        sessionId
      })
      if (error) {
        sendSentryError(
          `Failed to redirect to checkout - ${JSON.stringify(error)}`
        )
      }
    } catch (e) {
      sendSentryError(`Failed to pay - ${JSON.stringify(e)}`)
    }
  }

  if (donationOpen) {
    return <Donations closeDonations={() => setDonationOpen(false)} />
  }

  return (
    <Container>
      <Header />

      <Body>
        <ProgressContainer>
          <Progress value={25} stage={0} />
        </ProgressContainer>
        <ItemSelectorContainer>
          {bookingsProducts?.map((product) => {
            const orderedProduct = getProductFromOrderById({
              order,
              id: product.id
            })
            return isEmpty(product) ? null : (
              <ItemSelector
                key={`order-image-${product!.name}`}
                disabled={
                  isEmpty(orderedProduct) || orderedProduct!.quantity === 0
                }
                price={getProductPrice(product, currency)}
                handleAdd={() => handleAdd(product)}
                handleRemove={() => handleRemove(product)}
                imageSrc={`${PRODUCT_IMAGE_URL}/${product.name}.png`}
                name={get(product, 'name', '')}
                quantity={get(orderedProduct, 'quantity', 0)}
                desc={get(product, 'description', '')}
                reachLimit={reachLimit}
              />
            )
          })}
        </ItemSelectorContainer>
        <DonationButton
          show={booking?.donationsEnabled}
          donation={donation}
          onClick={() => setDonationOpen(true)}
        />
        <OrderDetailsWithPay
          onClick={handlePay}
          reachLimit={reachLimit}
          checkoutLimit={getPurchaseLimit(booking)}
        />
      </Body>
    </Container>
  )
}
