const en = {
  currencySymbol: '$',
  privacy: {
    text: 'Bunnings collects and manages your personal information in accordance with our',
    link: 'Privacy Policy'
  },
  order: {
    total: 'Total',
    orderNowButtonText: 'Order now',
    reachLimit: 'checkout limit has been reached.',
    noBooking: 'No community group has been set up to fundraise today'
  },
  nonSizzle: {
    title: 'Select a payment amount'
  },
  gift: {
    title: 'Select a gift amount',
    subHeading: '100% of your gift goes to',
    taxDeductible: {
      au: 'This gift may not be tax-deductible as many community groups are not deductible gift recipients. Bunnings does not collect cash or issue receipts on behalf of community groups.',
      nz: 'Bunnings does not collect cash or issue receipts on behalf of community groups.'
    },
    button: {
      update: 'Update gift',
      add: 'Add gift',
      addOne: 'Add a gift',
      cancel: 'Cancel',
      added: 'gift included',
      remove: 'Remove gift'
    }
  },
  donation: {
    title: 'Select a donation amount',
    subHeading: '100% of your donation goes to',
    taxDeductible: {
      au: 'Donations of $2 or more are tax deductible.',
      nz: 'Bunnings does not collect cash or issue receipts on behalf of community groups.'
    },
    button: {
      update: 'Update donation',
      add: 'Add donation',
      addOne: 'Add a donation',
      cancel: 'Cancel',
      added: 'donation included',
      remove: 'Remove donation'
    }
  },
  summary: {
    collectOrderButtonText: 'I have collected my order',
    mistakeButtonText: 'I made a mistake with my order',
    modal: {
      collectText: 'Are you sure you have collected your full order?',
      refundText: 'Please speak to your nearest community group member',
      no: 'No, not yet',
      yes: 'Yes, I have my order',
      ok: 'Okay'
    },
    instruction: {
      title: 'Show and collect',
      text: "When you're ready to collect, show this screen at the fundraising stall."
    },
    orderNumber: 'Order'
  },
  completed: {
    completedText: 'Thanks',
    completedTextContinued: 'for supporting our community!',
    refundedText: 'This order has been refunded.',
    orderAgainButtonText: 'Order again',
    feedbackButtonButtonText: 'Have feedback for us?'
  },
  error: {
    generic: 'Something went wrong, please try again.',
    invalidStoreCode: 'Please try again, this code seems incorrect'
  },
  login: {
    title: 'Enter the code on the poster to get started',
    buttonText: "Let's go"
  },
  progress: {
    order: 'Order',
    pay: 'Pay',
    collect: 'Collect',
    enjoy: 'Collect & Enjoy!',
    refunded: 'Refunded'
  }
}

export default en
