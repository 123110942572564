import React, { useEffect, useState } from 'react'
import { Box, Button, Text, InputField, Flex, styled, Spinner } from 'bumbag'
import { useParams } from 'react-router-dom'
import {
  useAppContext,
  useAppContextActions
} from '../../context/App/AppContext'
import * as sdk from '../../sdk'
import { Body, DonationBody } from './styled-components'
import { useTranslation } from 'react-i18next'
import { CENTS, CHECKOUT_LIMIT } from '../../constants'
import { get } from 'lodash'
import { sendSentryError } from '../../utils/sentry'
import { Header } from '../../components/Header'
import OrderDetails from '../../components/order/OrderDetails'

const numberValidation = /^(\d+\.?\d{0,2})?$/

export const PRE_DEFINED_AMOUNTS = [2, 5, 10]

const OrderButton = styled(Button)`
  height: 3rem;
  width: 100%;
  margin-top: 0.5rem;
  color: #fff;
`

const OrderSpinner = styled(Spinner)`
  position: relative;
  top: 0.25rem;
`

const OrderDetailsWithPay = ({
  onClick,
  reachLimit,
  checkoutLimit,
  amount
}: {
  onClick: Function
  reachLimit: boolean
  checkoutLimit: number
  amount: number
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }
  return (
    <OrderDetails
      reachLimit={reachLimit}
      totalPrice={amount}
      checkoutLimit={checkoutLimit}
    >
      <OrderButton
        palette="primary"
        disabled={amount > checkoutLimit || isLoading || amount < 1}
        onClick={handleClick}
      >
        {isLoading ? (
          <OrderSpinner color="white" />
        ) : (
          t('order.orderNowButtonText')
        )}
      </OrderButton>
    </OrderDetails>
  )
}

export function NoneSizzleEvent() {
  const { t } = useTranslation()
  const { setOpenAmount } = useAppContextActions()
  const { booking, openAmount, project, stripe } = useAppContext()

  const { bookingId } = useParams<{
    bookingId: string
  }>()

  const eventName = booking?.name

  const [amountInput, setAmountInput] = useState<string>(
    openAmount?.[bookingId]?.toFixed(CENTS) ?? ''
  )

  const updateAmount = (amount: string) => {
    const amountAsNumber = parseFloat(amount)
    setOpenAmount({
      bookingId,
      amount: amountAsNumber
    })
    setAmountInput(amount)
  }

  const currentAmount = parseFloat(amountInput || '0')

  const purchaseCap = booking?.purchaseCap || CHECKOUT_LIMIT

  async function handlePay() {
    if (currentAmount < 1) {
      return
    }

    if (!stripe) {
      sendSentryError('Stripe is not loaded')
      return
    }
    try {
      const res = await sdk.checkout({
        projectId: get(project, 'projectId')!,
        bookingId: get(booking, 'bookingId')!,
        openPriceInCents: currentAmount,
        lineItems: []
      })

      const sessionId = get(res, 'data.checkout.sessionId', '')
      const { error } = await stripe.redirectToCheckout({
        sessionId
      })
      if (error) {
        sendSentryError(
          `Failed to redirect to checkout - ${JSON.stringify(error)}`
        )
      }
    } catch (e) {
      sendSentryError(`Failed to pay - ${JSON.stringify(e)}`)
    }
  }

  const handlePageShow = (e: any) => {
    if (e.persisted) {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener('pageshow', handlePageShow)
    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  })

  return (
    <>
      <Header />
      <Body>
        <DonationBody>
          <Box textAlign="center">
            <Flex alignItems="center" flexDirection="column">
              <Text
                fontWeight="900"
                fontSize="20px"
                textTransform="capitalize"
                textAlign="left"
              >
                {eventName}
              </Text>

              <Text fontWeight="700" fontSize="18px" textAlign="left">
                {t('nonSizzle.title')}
              </Text>
            </Flex>

            <Box
              display="flex"
              padding="major-2"
              justifyContent="space-evenly"
              width="100%"
            >
              {PRE_DEFINED_AMOUNTS.map((amount) => {
                return (
                  <Button
                    key={amount}
                    // @ts-ignore
                    width="auto"
                    flexGrow="1"
                    margin="0.25rem"
                    onClick={() => {
                      updateAmount(amount.toFixed(CENTS))
                    }}
                  >
                    {t('currencySymbol')} {amount.toFixed(CENTS)}
                  </Button>
                )
              })}
            </Box>

            <InputField
              inputProps={{
                textAlign: 'center',
                paddingRight: '3.5rem',
                position: 'relative'
              }}
              value={amountInput ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value
                if (!numberValidation.test(value)) {
                  return
                }
                updateAmount(e.target.value ?? '')
              }}
              placeholder="Add different amount"
              margin="major-2"
              marginTop={'0rem'}
              addonBefore={<Button isStatic>{t('currencySymbol')}</Button>}
            />
          </Box>
        </DonationBody>

        <OrderDetailsWithPay
          onClick={handlePay}
          amount={currentAmount}
          reachLimit={currentAmount >= purchaseCap}
          checkoutLimit={purchaseCap}
        />
      </Body>
    </>
  )
}
