import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSubscription } from '@apollo/react-hooks'
import qs from 'qs'
import { TBooking, TOrder, TProduct, TProject, TSite, TStep } from '../../types'
import {
  getDonationsFromLocalStorage,
  getOrderFromLocalStorage,
  getTotalPrice,
  updateLocalStorageState
} from '../../utils/order'
import { AppActionContext, AppStateContext } from './AppContext'
import { find, get, keyBy, sortBy } from 'lodash'
import * as sdk from '../../sdk'
import { sendSentryError, setupSentryContext } from '../../utils/sentry'
import {
  getCountryByProjectId,
  getProjectIdByStoreCode
} from '../../utils/project'
import { getProductsWithPrice } from '../../utils/product'
import {
  ORDER_STATUS,
  REFUND_STATUS,
  SAUSAGE_SIZZLE_EVENT
} from '../../constants'
import { loadStripe, Stripe } from '@stripe/stripe-js'

function getDonationOrGift({
  projectId,
  isRedRun,
  isCharity
}: {
  projectId: string
  isRedRun: boolean
  isCharity: boolean
}) {
  const country = getCountryByProjectId(projectId)

  if (country === 'nz') {
    return 'gift'
  }

  return isRedRun || isCharity ? 'donation' : 'gift'
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [donation, setDonation] = useState<number | undefined>(undefined)
  const [openAmount, setOpenAmount] = useState<Record<string, number>>({})
  const [project, setProject] = useState<TProject | undefined>(undefined)
  const [order, setOrder] = useState<TOrder>([])
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [productsById, setProducts] = useState<{
    [productId: string]: TProduct
  }>({})
  const [booking, setBooking] = useState<TBooking | undefined>(undefined)
  const [bookings, setBookings] = useState<TBooking[]>([])
  const [site, setSite] = useState<TSite | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [step, setStep] = useState<TStep>(TStep.order)
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const history = useHistory()
  const { storeCode, bookingId } = useParams<{
    storeCode: string
    bookingId: string
  }>()
  const location = useLocation()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const orderNumber = get(queryParams, 'orderNumber', '') as string
  const projectId = get(queryParams, 'projectId', '') as string

  useEffect(() => {
    const getStripeClient = async () => {
      const stripeClient = await loadStripe(
        get(project, 'stripePublishableKey')!
      )
      setStripe(stripeClient)
    }
    if (project && !stripe) {
      getStripeClient()
    }
  }, [project, stripe])
  function handleSetOpenAmount({
    bookingId,
    amount
  }: {
    bookingId: string
    amount: number
  }) {
    setOpenAmount({
      ...openAmount,
      [bookingId]: amount
    })
  }

  useEffect(() => {
    setupSentryContext(storeCode)
  }, [storeCode])

  useEffect(() => {
    if (bookings && bookings?.length > 1) {
      setStep(TStep.events)
      return
    }

    if (bookings?.length > 0) {
      bookings[0].eventType === SAUSAGE_SIZZLE_EVENT
        ? setStep(TStep.order)
        : setStep(TStep.donations)
    }
  }, [bookings])

  useSubscription(sdk.ORDER_UPDATE_SUBSCRIPTION, {
    skip: !projectId || !orderNumber || !bookingId,
    variables: { projectId, orderNumber, bookingId },
    onSubscriptionData: async (options: any) => {
      try {
        if (get(options, 'subscriptionData.data.orderUpdated')) {
          const res = await sdk.getOrder({ orderNumber, bookingId, projectId })
          const order = get(res, 'data.findOrder')
          if (
            get(order, 'refundStatus') === REFUND_STATUS.succeeded ||
            get(order, 'orderStatus') === ORDER_STATUS.completed
          ) {
            history.push(
              `/${storeCode}/completed?orderNumber=${orderNumber}&projectId=${projectId}&bookingId=${bookingId}`,
              {
                order
              }
            )
          }
        }
      } catch (e) {
        sendSentryError(
          `Faild to handle order update event - ${JSON.stringify(e)}`
        )
      }
    }
  })

  const getProjectAndProducts = useCallback(async () => {
    try {
      setLoading(true)
      const res = await sdk.getData({
        projectId: getProjectIdByStoreCode(storeCode)!,
        storeCode
      })
      const project = get(res, 'data.findProject', {})
      const products = get(res, 'data.findProducts.data', [])
      const prices = get(res, 'data.findPrices.data', [])
      const site = get(res, 'data.findSite', {})
      // TODO: get first vendor for now
      const bookings = sortBy(
        get(res, 'data.findBookingsForToday', []),
        (booking: TBooking) => booking.eventType !== SAUSAGE_SIZZLE_EVENT
      )

      const productsWithPrice = getProductsWithPrice({
        products,
        prices,
        project
      })

      setProject(project)
      setSite(site)
      setBookings(bookings)
      if (bookings.length > 0) {
        if (bookingId) {
          setBooking(
            find(
              bookings,
              (booking: TBooking) => booking.bookingId === bookingId
            )
          )
        } else {
          setBooking(bookings[0])
        }
      }
      setOrder(
        getOrderFromLocalStorage({
          productsWithPrice,
          storeCode,
          bookingId
        })
      )
      setProducts(keyBy(productsWithPrice, 'id'))
      setLoading(false)
    } catch (e) {
      sendSentryError(`Failed to get project - ${JSON.stringify(e)}`)
      setLoading(false)
      if (get(location, 'state.login')) {
        history.push('/', { error: true })
      } else {
        setShowError(true)
      }
    }
  }, [storeCode, location, history, bookingId])

  useEffect(() => {
    if (bookingId) {
      setBooking(
        find(bookings, (booking: TBooking) => booking.bookingId === bookingId)
      )
    } else {
      setBooking(undefined)
    }
  }, [bookingId, bookings])

  useEffect(() => {
    const donation = getDonationsFromLocalStorage({
      bookingId
    })
    setDonation(donation)
    getProjectAndProducts()
  }, [setDonation, getProjectAndProducts, bookingId])

  useEffect(() => {
    const totalPrice = getTotalPrice(order, donation)
    setTotalPrice(totalPrice)
    updateLocalStorageState({ storeCode, order, donation, bookingId })
  }, [donation, order, storeCode, bookingId])

  return (
    <AppStateContext.Provider
      value={{
        project,
        order,
        productsById,
        bookings,
        booking,
        site,
        loading,
        showError,
        step,
        donation,
        openAmount,
        totalPrice,
        storeCode,
        stripe,
        donationOrGift: getDonationOrGift({
          projectId: booking?.projectId as string,
          isRedRun: booking?.isRedRun || false,
          isCharity: booking?.vendor.isCharity || false
        })
      }}
    >
      <AppActionContext.Provider
        value={{
          setDonation,
          setOpenAmount: handleSetOpenAmount,
          setOrder,
          setProducts,
          setLoading,
          setShowError,
          setStep,
          setBooking
        }}
      >
        {children}
      </AppActionContext.Provider>
    </AppStateContext.Provider>
  )
}
